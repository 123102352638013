import { FunctionComponent, useState } from "react";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import { useAppDispatch, useAppSelector } from "../../Redux/store/hooks";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { edit_EditRedis } from "../../Redux/Reducers/Redis/edit_Redis_slicer";
import LoopIcon from "@mui/icons-material/Loop";

interface MapStartegyprops {
  valuepopup: number;
  editdata: any;
}

const RedisMessage: FunctionComponent<MapStartegyprops> = (props: any) => {
  const dispatcher = useAppDispatch();

  const pageName = "Historical Candle Data";
  const valueit = sessionStorage.getItem("permission");
  const menuList: any = JSON.parse(
    valueit === null
      ? localStorage.getItem("permission") || "null"
      : sessionStorage.getItem("permission") || "null"
  );
  const index: number = menuList.findIndex((indexId: any) => {
    return indexId.name === pageName;
  });
  const MenuOfUserpermission = menuList[index];

  // const data: any = useAppSelector((state) => state?.ListRedisSub?.data);

  //  download file
  const downloadExcel = () => {
    try {
      const worksheet = XLSX.utils.json_to_sheet(props?.editdata?.data);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

      const excelBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      const file = new Blob([excelBuffer], {
        type: "application/octet-stream",
      });
      saveAs(file, `${props?.editdata?.key}.xlsx`);
    } catch (error) {
      console.error("Error generating Excel file:", error);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        // alignItems: "center",
      }}
    >
      {MenuOfUserpermission?.permission?.edit ? (
        <>
          <DownloadForOfflineIcon
            style={{ cursor: "pointer", color: "#F2A618" }}
            onClick={downloadExcel}
            titleAccess="Download Historical Data"
          />
          <span
            style={{ cursor: "pointer", color: "#F2A618" }}
            title="Update Historical Data"
            onClick={() => {
              dispatcher(edit_EditRedis({}));
            }}
          >
            <LoopIcon />
          </span>
        </>
      ) : (
        "-"
      )}
    </div>
  );
};

export default RedisMessage;
