import React, { FunctionComponent } from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Stack from "@mui/material/Stack";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import "../Page.css";
import Grids from "../../Component/Grides";
import { useAppDispatch, useAppSelector } from "../../Redux/store/hooks";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { Alert, LinearProgress, Snackbar, Typography } from "@mui/material";
import UsageControlMessage from "./RedisMessage";
import {
  clearDataListRedis,
  list_ListRedis,
} from "../../Redux/Reducers/Redis/list_Redis_slicer";
import { list_ListRedisSub } from "../../Redux/Reducers/Redis/list_RedisSub_slicer";
import { false_switchEditRedis_response } from "../../Redux/Reducers/Redis/edit_Redis_slicer";
import { false_switchRedisvalues } from "../../Redux/Reducers/Datagrid-values/Redis-values-slicer";

interface RedisProps {}

const Redis: FunctionComponent<RedisProps> = () => {
  const dispatcher = useAppDispatch();

  const breadcrumbs = [
    <Typography
      sx={{
        fontSize: "17px",
        fontFamily: "Roboto",
      }}
    >
      Historical Candle Data
    </Typography>,
  ];
  const datasubredis: any = useAppSelector(
    (state) => state?.ListRedisSub?.data
  );
  const dataList: any = useAppSelector((state) => state?.ListRedisSub?.data);

  const data = useAppSelector((state) => state?.ListRedis?.data);
  const isLoadingdata = useAppSelector((state) => state?.ListRedis?.isLoading);
  const Edit: any = useAppSelector((state) => state?.EditRedis?.data);
  const isLoadingdataedit = useAppSelector(
    (state) => state?.EditRedis?.isLoading
  );
  const EditResponse = useAppSelector(
    (state) => state.EditRedis.switchResponse
  );

  const column = [
    {
      sortable: true,
      headerName: "Keys",
      flex: 1,
      field: "key",
      valueGetter: (params: any) => {
        return params?.row?.key;
      },
      renderCell: (params: GridRenderCellParams<any>) => {
        const algoName = params?.row?.key;

        return <div title={algoName}>{algoName}</div>;
      },
    },

    true
      ? {
          headerName: "Action",
          field: "Action",
          flex: 1,
          type: "actions",
          renderCell: (params: any) => {
            return (
              <span
                onClick={() => {
                  localStorage.setItem("Redisfilter", params.row.key);
                }}
              >
                <UsageControlMessage
                  valuepopup={params.row.id}
                  editdata={params.row}
                ></UsageControlMessage>
              </span>
            );
          },
        }
      : {
          flex: 1,
          type: "actions",
          hide: true,
          display: false,
          renderCell: (params: any) => {
            return null;
          },
        },
  ];

  const initialColumnVisibilityModel: any = {
    key: true,

    Action: true,
  };
  const [columnVisibilityModel, setColumnVisibilityModel] = React.useState(
    initialColumnVisibilityModel
  );
  React.useEffect(() => {
    dispatcher(list_ListRedis(""));
    if (EditResponse) {
      dispatcher(false_switchRedisvalues());
    }
    return () => {
      dispatcher(clearDataListRedis());
    };
  }, [dispatcher, Edit, EditResponse]);

  const editaction = (
    <button
      onClick={() => {
        dispatcher(false_switchEditRedis_response()); // Close the Snackbar immediately
      }}
      style={{
        color: "white",
        backgroundColor: "transparent",
        border: "none",
        cursor: "pointer",
      }}
    >
      Close
    </button>
  );
  return (
    <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={EditResponse} // Use alertOpen to control the Snackbar's visibility
        autoHideDuration={2000} // Set the desired auto-hide duration (in milliseconds)
        onClose={() => dispatcher(false_switchEditRedis_response())}
        key={`top-right`}
        action={editaction}
      >
        <Alert
          onClose={() => dispatcher(false_switchEditRedis_response())}
          severity="success"
        >
          {/* {props?.successMessage?.message} */}
          {Edit?.message}
        </Alert>
      </Snackbar>

      <div>
        <LinearProgress
          style={{
            color: "black",
            width: "100%",
            marginTop: "-15px",
            display: isLoadingdata || isLoadingdataedit ? "block" : "none",
          }}
        />
      </div>

      <Stack
        spacing={10}
        className="breadcrumbs"
        sx={{ margin: "0px 0px 1.5% 1%" }}
      >
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          {breadcrumbs}
        </Breadcrumbs>
      </Stack>
      <Grids
        row={data}
        column={column}
        gridesbutton
        columnVisibilityModel={columnVisibilityModel}
        setColumnVisibilityModel={setColumnVisibilityModel}
        pagination
        Userpermissions={undefined}
      ></Grids>
    </div>
  );
};

export default Redis;
