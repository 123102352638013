import { createSlice } from "@reduxjs/toolkit";
// this code for slice value for list usercontrol
export const ListRedisSub = createSlice({
  name: "ListRedisSub",
  initialState: {
    isLoading: false,
    isSuccess: false,
    isFirst: true,
    data: [],
  },
  reducers: {
    list_ListRedisSub: (state, action?) => {
      state.isLoading = true;
    },
    success_api_call_ListRedisSub: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
      state.isSuccess = true;
      state.isFirst = true;
    },
    failure_api_call_ListRedisSub: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
    ListRedisSub_is_first: (state, action) => {
      state.isFirst = false;
    },
    clearDataListRedisSub: (state) => {
      state.data = [];
    },
  },
});
export default ListRedisSub.reducer;
export const {
  list_ListRedisSub,
  success_api_call_ListRedisSub,
  failure_api_call_ListRedisSub,
  ListRedisSub_is_first,
  clearDataListRedisSub,
} = ListRedisSub.actions;
