import { createSlice } from "@reduxjs/toolkit";
// this code for slice value for Add  trade setup
export const TreadSetupManual = createSlice({
  name: "AddTreadSetup_Manual",
  initialState: {
    api_completed: false,
    isLoading: false,
    isSuccess: false,
    isFirst: true,
    switchResponse: false,
    data: [],
  },
  reducers: {
    add_AddTreadSetup_Manual: (state, _) => {
      state.isLoading = true;
    },
    success_api_call_add_AddTreadSetup_Manual: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
      state.isSuccess = true;
      state.api_completed = true;
      state.isFirst = true;
      state.switchResponse = true;
    },
    failure_api_call_add_AddTreadSetup_Manual: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      state.isSuccess = false;
      state.api_completed = true;
      state.switchResponse = true;
    },
    add_AddTreadSetup_is_success_Manual: (state, action) => {
      state.isSuccess = false;
    },
    create_AddTreadSetup_complete_api_Manual: (state, action) => {
      state.api_completed = false;
    },
    clear_add_Data_Manual: (state) => {
      state.data = [];
    },
    false_switchTreadSetupadd_response_Manual: (state) => {
      state.switchResponse = false;
    },
  },
});
export default TreadSetupManual.reducer;
export const {
  add_AddTreadSetup_Manual,
  success_api_call_add_AddTreadSetup_Manual,
  failure_api_call_add_AddTreadSetup_Manual,
  create_AddTreadSetup_complete_api_Manual,
  add_AddTreadSetup_is_success_Manual,
  clear_add_Data_Manual,
  false_switchTreadSetupadd_response_Manual,
} = TreadSetupManual.actions;
