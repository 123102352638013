import React, { FunctionComponent } from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Stack from "@mui/material/Stack";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import "../Page.css";
import Grids from "../../Component/Grides";
import { useAppDispatch, useAppSelector } from "../../Redux/store/hooks";
import {
  Alert,
  Button,
  LinearProgress,
  Snackbar,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import MenuMessage from "./MenuMessage";
import DialogMenu from "../../Component/DialogForFormMenu";
import Dialogs from "../../Component/Dialog";
import {
  edit_EditMenu,
  false_switchEditMenu_response,
} from "../../Redux/Reducers/Menu/edit_Menu_slicer";
import { list_MenuList } from "../../Redux/Reducers/Menu/list_Menu_slicer";
import { false_switchMenuvalues } from "../../Redux/Reducers/Datagrid-values/Menu-values-slicer";
import { false_switchDeleteMenuvalues } from "../../Redux/Reducers/Datagrid-values/DeleteMenu-values-slicer";
import {
  delete_DeleteMenu,
  false_switchDeleteMenu_response,
} from "../../Redux/Reducers/Menu/delete_Menu_slicer";
import {
  add_AddMenu,
  false_switchAddMenu_response,
} from "../../Redux/Reducers/Menu/add_Menu_slicer";
import { GridRenderCellParams } from "@mui/x-data-grid";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import PersonIcon from "@mui/icons-material/Person";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import WalletIcon from "@mui/icons-material/Wallet";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import DashboardIcon from "@mui/icons-material/Dashboard";
import VisibilityIcon from "@mui/icons-material/Visibility";
import GroupIcon from "@mui/icons-material/Group";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import TimelineIcon from "@mui/icons-material/Timeline";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import SettingsIcon from "@mui/icons-material/Settings";
import LayersIcon from "@mui/icons-material/Layers";
import AutoGraphIcon from "@mui/icons-material/AutoGraph";
import KeyIcon from "@mui/icons-material/Key";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import CandlestickChartIcon from "@mui/icons-material/CandlestickChart";
import {
  clearUser_permission,
  list_User_permission,
} from "../../Redux/Reducers/User_permission/list_User_permission_slicer";
import KeyboardCommandKeyIcon from "@mui/icons-material/KeyboardCommandKey";
import HistoryIcon from "@mui/icons-material/History";
interface MenuProps {}

const iconComponents: Record<string, React.ElementType> = {
  LogoutOutlinedIcon,
  PersonIcon,
  TrendingUpIcon,
  WalletIcon,
  ManageAccountsIcon,
  DashboardIcon,
  VisibilityIcon,
  GroupIcon,
  AddCircleOutlineIcon,
  TimelineIcon,
  AnalyticsIcon,
  CalendarMonthIcon,
  SettingsIcon,
  LayersIcon,
  AutoGraphIcon,
  KeyIcon,
  MenuOpenIcon,
  AssignmentIndIcon,
  CandlestickChartIcon,
  KeyboardCommandKeyIcon,
  HistoryIcon,
};

const Menu: FunctionComponent<MenuProps> = () => {
  const dispatcher = useAppDispatch();

  const breadcrumbs = [
    <Typography
      sx={{
        fontSize: "17px",
        fontFamily: "Roboto",
      }}
    >
      Menus and Permission
    </Typography>,
    <Typography
      sx={{
        fontSize: "16px",
        color: "#BDBDBD",
        fontFamily: "Roboto",
      }}
    >
      Menu
    </Typography>,
  ];

  const [alignment, setAlignment] = React.useState(true);

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: boolean
  ) => {
    if (newAlignment !== null) {
      setAlignment(newAlignment);
    }
  };
  const [portal, setportal] = React.useState("Admin");

  const handleChangeforportal = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string
  ) => {
    if (newAlignment !== null) {
      setportal(newAlignment);
    }
  };

  const heading = (
    <>
      <ToggleButtonGroup
        color="primary"
        sx={{ marginRight: "10px" }}
        value={alignment}
        exclusive
        onChange={handleChange}
        aria-label="Platform"
      >
        <ToggleButton
          value={true}
          title="Active Menus"
          sx={{
            textTransform: "capitalize",
            height: "39px",
            marginTop: "3px",
            width: {
              xs: 60,
              sm: 60,
              md: 60,
              lg: 75,
              xl: 80,
            },
          }}
        >
          Active
        </ToggleButton>
        <ToggleButton
          value={false}
          title="Inactive Menus"
          sx={{
            textTransform: "capitalize",
            height: "39px",
            marginTop: "3px",
            width: {
              xs: 60,
              sm: 60,
              md: 60,
              lg: 60,
              xl: 80,
            },
          }}
        >
          Inactive
        </ToggleButton>
      </ToggleButtonGroup>
      <ToggleButtonGroup
        color="primary"
        sx={{ marginRight: "10px" }}
        value={portal}
        exclusive
        onChange={handleChangeforportal}
        aria-label="Platform"
      >
        <ToggleButton
          value="Admin"
          title="Admin Menus"
          sx={{
            textTransform: "capitalize",
            height: "39px",
            marginTop: "3px",
            width: {
              xs: 60,
              sm: 60,
              md: 60,
              lg: 75,
              xl: 80,
            },
          }}
        >
          Admin
        </ToggleButton>
        <ToggleButton
          value="User"
          title="User Menus"
          sx={{
            textTransform: "capitalize",
            height: "39px",
            marginTop: "3px",
            width: {
              xs: 60,
              sm: 60,
              md: 60,
              lg: 60,
              xl: 80,
            },
          }}
        >
          User
        </ToggleButton>
      </ToggleButtonGroup>
    </>
  );

  const data = useAppSelector((state) => state?.User_permission?.data);
  const addData: any = useAppSelector((state) => state?.AddMenu?.data);
  const addDataIsLoading = useAppSelector((state) => state?.AddMenu?.isLoading);
  const editData: any = useAppSelector((state) => state?.EditMenu?.data);
  const editDataIsLoading = useAppSelector(
    (state) => state?.EditMenu?.isLoading
  );
  const deleteData: any = useAppSelector((state) => state?.DeleteMenu?.data);
  const DeleteisLoading: any = useAppSelector(
    (state) => state?.DeleteMenu?.isLoading
  );
  const isLoadingdata = useAppSelector(
    (state) => state?.User_permission?.isLoading
  );
  const AddResponse = useAppSelector((state) => state.AddMenu.switchResponse);
  const EditResponse = useAppSelector((state) => state.EditMenu.switchResponse);
  const switchResponsealgodelete = useAppSelector(
    (state) => state.DeleteMenu.switchResponse
  );
  const Uservaluesalgodelete: any = useAppSelector(
    (state) => state?.DeleteMenuvalues?.data
  );
  const switchOpenalgodelete = useAppSelector(
    (state) => state?.DeleteMenuvalues?.switch
  );
  const Uservalues: any = useAppSelector((state) => state?.Menuvalues?.data);
  const switchOpen = useAppSelector((state) => state?.Menuvalues?.switch);

  const column = [
    {
      sortable: true,
      headerName: "Icon",
      flex: 1,
      field: "icon",
      valueGetter: (params: any) => {
        return params.row.icon;
      },
      renderCell: (params: GridRenderCellParams<any>) => {
        const Iconrow = params?.row?.icon;
        const IconComponent = iconComponents[Iconrow];

        return (
          <div title={Iconrow}>
            {IconComponent ? <IconComponent /> : Iconrow}
          </div>
        );
      },
    },
    {
      headerName: "Name",
      sortable: true,
      valueGetter: (params: any) => {
        return params.row.name;
      },
      field: "name",
      flex: 1,
    },
    {
      sortable: true,
      headerName: "Path Name ",
      flex: 1,
      field: "path_name",
      valueGetter: (params: any) => {
        return params.row.path_name;
      },
      renderCell: (params: GridRenderCellParams<any>) => {
        const PathMenu: any = params.row.path_name;

        return (
          <div title={PathMenu ? PathMenu : "-"}>
            {PathMenu ? PathMenu : "-"}
          </div>
        );
      },
    },
    {
      sortable: true,
      headerName: "Main Menu",
      flex: 1,
      field: "main_menu",
      renderCell: (params: GridRenderCellParams<any>) => {
        const SubMenu: any = params?.row?.main_menu?.name;

        return (
          <div title={SubMenu ? SubMenu : "-"}>{SubMenu ? SubMenu : "-"}</div>
        );
      },
    },

    {
      sortable: true,
      headerName: "Portal ",
      flex: 1,
      field: "portal",
      valueGetter: (params: any) => {
        return params.row.portal;
      },
    },
    true
      ? {
          headerName: "Action",
          field: "Action",
          flex: 1,
          type: "actions",
          renderCell: (params: any) => {
            return (
              <MenuMessage
                valuepopup={params.row.id}
                editdata={params.row}
              ></MenuMessage>
            );
          },
        }
      : {
          flex: 1,
          type: "actions",
          hide: true,
          display: false,
          renderCell: (params: any) => {
            return null;
          },
        },
  ];

  React.useEffect(() => {
    if (alignment) {
      dispatcher(list_MenuList([{ status: alignment }]));
    } else {
      dispatcher(list_MenuList([{ Pagemethod: portal }]));
    }
    if (sessionStorage.getItem("roleIDfordatalist")) {
      dispatcher(
        list_User_permission([
          {
            portal: portal,
            status: alignment,
            roleId: sessionStorage.getItem("roleIDfordatalist"),
          },
        ])
      );
    }
    if (EditResponse) {
      dispatcher(false_switchMenuvalues());
    }
    if (switchResponsealgodelete) {
      dispatcher(false_switchDeleteMenuvalues());
    }
    return () => {
      dispatcher(clearUser_permission());
    };
  }, [
    addData,
    editData,
    deleteData,
    dispatcher,
    switchResponsealgodelete,
    EditResponse,
    alignment,
    portal,
  ]);
  const Addaction = (
    <button
      onClick={() => {
        dispatcher(false_switchAddMenu_response()); // Close the Snackbar immediately
      }}
      style={{
        color: "white",
        backgroundColor: "transparent",
        border: "none",
        cursor: "pointer",
      }}
    >
      Close
    </button>
  );
  const deletaction = (
    <button
      onClick={() => {
        dispatcher(false_switchDeleteMenu_response()); // Close the Snackbar immediately
      }}
      style={{
        color: "white",
        backgroundColor: "transparent",
        border: "none",
        cursor: "pointer",
      }}
    >
      Close
    </button>
  );
  const editaction = (
    <button
      onClick={() => {
        dispatcher(false_switchEditMenu_response()); // Close the Snackbar immediately
      }}
      style={{
        color: "white",
        backgroundColor: "transparent",
        border: "none",
        cursor: "pointer",
      }}
    >
      Close
    </button>
  );
  const initialColumnVisibilityModel: any = {
    icon: true,
    name: true,
    path_name: true,
    main_menu: true,
    portal: true,
    Action: true,
  };
  const [columnVisibilityModel, setColumnVisibilityModel] = React.useState(
    initialColumnVisibilityModel
  );
  return (
    <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <div>
        <LinearProgress
          style={{
            color: "black",
            width: "100%",
            marginTop: "-15px",
            display:
              isLoadingdata || addDataIsLoading || editDataIsLoading
                ? "block"
                : "none",
          }}
        />
      </div>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={AddResponse} // Use alertOpen to control the Snackbar's visibility
        autoHideDuration={2000} // Set the desired auto-hide duration (in milliseconds)
        onClose={() => dispatcher(false_switchAddMenu_response())}
        key={`top-right`}
        action={Addaction}
      >
        <Alert
          onClose={() => dispatcher(false_switchAddMenu_response())}
          severity="success"
        >
          {addData.message}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={EditResponse} // Use alertOpen to control the Snackbar's visibility
        autoHideDuration={2000} // Set the desired auto-hide duration (in milliseconds)
        onClose={() => dispatcher(false_switchEditMenu_response())}
        key={`top-right`}
        action={editaction}
      >
        <Alert
          onClose={() => dispatcher(false_switchEditMenu_response())}
          severity="success"
        >
          {editData.message}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={switchResponsealgodelete} // Use alertOpen to control the Snackbar's visibility
        autoHideDuration={2000} // Set the desired auto-hide duration (in milliseconds)
        onClose={() => dispatcher(false_switchDeleteMenu_response())}
        key={`top-right`}
        action={deletaction}
      >
        <Alert
          onClose={() => dispatcher(false_switchDeleteMenu_response())}
          severity="success"
        >
          {deleteData.message}
        </Alert>
      </Snackbar>
      <div>
        <LinearProgress
          style={{
            color: "black",
            width: "100%",
            marginTop: "-15px",
            display:
              isLoadingdata ||
              addDataIsLoading ||
              editDataIsLoading ||
              DeleteisLoading
                ? "block"
                : "none",
          }}
        />
      </div>

      <Stack
        spacing={10}
        className="breadcrumbs"
        sx={{ margin: "0px 0px 1.5% 1%" }}
      >
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          {breadcrumbs}
        </Breadcrumbs>
      </Stack>
      <Grids
        row={data ?? []}
        column={column}
        gridesbutton
        Users={heading}
        // getRowHeight={() => "auto"}
        pagination
        dialog={
          <DialogMenu
            successMessage={addData}
            isLoading={addDataIsLoading}
            sx={{
              padding: "15px",
              background: "linear-gradient(to right, #FFFA02,#F2A618 )",
              color: "black",
              width: {
                xs: 60,
                sm: 100,
                md: 100,
                lg: 100,
                xl: 150,
              },
              height: "37px",
              borderRadius: "5px",
              fontFamily: "Roboto",
              textTransform: "capitalize",
              marginLeft: "10px",
              "&:hover": {
                background: "linear-gradient(to right, #FFFA02,#F2A618 )",
              },
            }}
            getValues={(v: any) => {
              dispatcher(add_AddMenu(v));
            }}
            Buttondialog={["Cancel"]}
            Dialogtitle={"Add Menu"}
            Buttonname={"Add New"}
          />
        }
        Userpermissions={undefined}
        columnVisibilityModel={columnVisibilityModel}
        setColumnVisibilityModel={setColumnVisibilityModel}
      ></Grids>
      <Dialogs
        maxWidth={"xs"}
        open={switchOpen}
        subtitle={"Warning"}
        setOpen={() => dispatcher(false_switchMenuvalues())}
        Dialog_Content={
          Uservalues.is_enabled !== true
            ? "Do you want to de-activate the role ?"
            : "Do you want to activate the role ?"
        }
        Dialog_Actions={
          <>
            <Button
              sx={{
                width: "18%",
                color: "black",
                borderRadius: "5px",
                textTransform: "capitalize",
                border: "1px solid #F2A618",
                padding: "5px 0",
                backgroundColor: "white",
                "&:hover": { backgroundColor: "white" },
              }}
              onClick={() => dispatcher(false_switchMenuvalues())}
            >
              No
            </Button>
            <Button
              autoFocus
              sx={{
                background: "linear-gradient(to right, #FFFA02,#F2A618 )",
                "&:hover": {
                  background: "linear-gradient(to right, #FFFA02,#F2A618 )",
                },
                width: "18%",
                color: "black",
                borderRadius: "5px",
                textTransform: "capitalize",
                marginLeft: "5%",
              }}
              onClick={() => dispatcher(edit_EditMenu(Uservalues))}
            >
              yes
            </Button>
          </>
        }
      />
      <Dialogs
        maxWidth={"xs"}
        open={switchOpenalgodelete}
        subtitle={"Warning"}
        setOpen={() => dispatcher(false_switchDeleteMenuvalues())}
        Dialog_Content={"Do you want to delete?"}
        Dialog_Actions={
          <>
            <Button
              sx={{
                width: "18%",
                color: "black",
                borderRadius: "5px",
                textTransform: "capitalize",
                border: "1px solid #F2A618",
                padding: "5px 0",
                backgroundColor: "white",
                "&:hover": { backgroundColor: "white" },
              }}
              onClick={() => dispatcher(false_switchDeleteMenuvalues())}
            >
              No
            </Button>
            <Button
              autoFocus
              sx={{
                background: "linear-gradient(to right, #FFFA02,#F2A618 )",
                "&:hover": {
                  background: "linear-gradient(to right, #FFFA02,#F2A618 )",
                },
                width: "18%",
                color: "black",
                borderRadius: "5px",
                textTransform: "capitalize",
                marginLeft: "5%",
              }}
              onClick={() =>
                dispatcher(delete_DeleteMenu(Uservaluesalgodelete))
              }
            >
              yes
            </Button>
          </>
        }
      />
    </div>
  );
};

export default Menu;
