import React, { FunctionComponent, useState } from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Stack from "@mui/material/Stack";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import "../Page.css";
import Grids from "../../Component/Grides";
import { useAppDispatch, useAppSelector } from "../../Redux/store/hooks";
import { GridRenderCellParams } from "@mui/x-data-grid";
import {
  Alert,
  Button,
  LinearProgress,
  Snackbar,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from "@mui/material";
import Dialogs from "../../Component/Dialog";
import { false_switchDeleteTreadSetupvalues } from "../../Redux/Reducers/Datagrid-values/DeleteTrade-setup-values-slicer";
import { false_switchTreadSetupvalues } from "../../Redux/Reducers/Datagrid-values/Tradesetup-values-slicer";
import {
  clearData,
  list_TreadSetupList,
} from "../../Redux/Reducers/Trade-setup/list_Trade-setup_slicer";
import {
  edit_EditTreadSetup,
  false_switchTreadSetup_response,
} from "../../Redux/Reducers/Trade-setup/edit_Trade-setup_slicer";
import {
  delete_TreadSetup,
  false_switchdeleteTreadSetup_response,
} from "../../Redux/Reducers/Trade-setup/delete_Trade-setup_slicer";
import {
  add_AddTreadSetup,
  false_switchTreadSetupadd_response,
} from "../../Redux/Reducers/Trade-setup/add_Trade-setup_slicer";
import TradesetupMessage from "./TradesetupMessage";
import DialogTradesetup from "../../Component/DialogForFormTradesetup";
import Tradesetupfilter from "../../Component/Tradesetupfilter";
import { edit_filtermap } from "../../Redux/Reducers/Filtervalue/filtermap";
import Warning from "../svg_image/Waring.gif";
import RefreshIcon from "@mui/icons-material/Refresh";
import { list_StrategiesList } from "../../Redux/Reducers/Strategies_api/list_StrategiesList_slicer";
import { list_AddorEditList } from "../../Redux/Reducers/Trade-setup/listaddoredit_Trade-setup_slicer";
import { list_AccountList } from "../../Redux/Reducers/Card_api/list_slicer";
import { list_ListGroupAccount } from "../../Redux/Reducers/GroupAccount/list_GroupAccount_slicer";
import TradesetupView from "../../Component/TradesetupView";
import {
  add_AddTreadSetup_Manual,
  false_switchTreadSetupadd_response_Manual,
} from "../../Redux/Reducers/Trade-setup/add_Trade-setup_manual_slicer";

interface MapStrategiesProps {}

const TreadSetup: FunctionComponent<MapStrategiesProps> = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState<any>(null);

  const handleDoubleClick = (params: GridRenderCellParams<any>) => {
    setSelectedRowData(params.row);
    setDrawerOpen(true);
  };

  const dispatcher = useAppDispatch();
  const breadcrumbs = [
    <Typography
      sx={{
        fontSize: "17px",
        fontFamily: "Roboto",
      }}
    >
      Trade Setup
    </Typography>,
  ];

  const addDatabuyorsell: any = useAppSelector(
    (state) => state?.AddTreadSetup_Manual?.data
  );
  const AddResponsebuyorsell = useAppSelector(
    (state) => state.AddTreadSetup_Manual.switchResponse
  );
  const data = useAppSelector((state) => state?.TreadSetupList?.data);
  const addData: any = useAppSelector((state) => state?.AddTreadSetup?.data);
  const addDataIsLoading = useAppSelector(
    (state) => state?.AddTreadSetup?.isLoading
  );
  const [alignment, setAlignment] = React.useState("Individual");

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string
  ) => {
    if (newAlignment !== null) {
      setAlignment(newAlignment);
    }
  };
  const [status, setStatus] = useState(0);

  const handleStatusChange = (newStatus: number) => {
    setStatus(newStatus);
    dispatcher(list_TreadSetupList({ status: newStatus }));
  };
  const editData: any = useAppSelector((state) => state?.EditTreadSetup?.data);
  const editDataIsLoading: any = useAppSelector(
    (state) => state?.EditTreadSetup?.isLoading
  );
  const deleteData: any = useAppSelector(
    (state) => state?.DeleteTreadSetup?.data
  );
  const isLoadingdata = useAppSelector(
    (state) => state?.TreadSetupList?.isLoading
  );
  const AddResponse = useAppSelector(
    (state) => state.AddTreadSetup.switchResponse
  );
  const switchResponse = useAppSelector(
    (state) => state.EditTreadSetup.switchResponse
  );
  const Uservalues: any = useAppSelector(
    (state) => state.TreadSetupvalues.data
  );
  const switchOpen = useAppSelector((state) => state.TreadSetupvalues.switch);
  const switchResponsealgodelete = useAppSelector(
    (state) => state.DeleteTreadSetup.switchResponse
  );
  const Uservaluesalgodelete: any = useAppSelector(
    (state) => state.DeleteTreadSetupvalues.data
  );
  const switchOpenalgodelete = useAppSelector(
    (state) => state.DeleteTreadSetupvalues.switch
  );

  const pageName = "/trade-setup";
  const valueit = sessionStorage.getItem("permission");
  const menuList: any = JSON.parse(
    valueit === null
      ? localStorage.getItem("permission") || "null"
      : sessionStorage.getItem("permission") || "null"
  );

  const test_val = menuList
    .map((data: any) => {
      return data.sub_menus.filter((child: any) => {
        return child.path_name === pageName;
      });
    })
    .filter((final: any) => {
      return final.length > 0;
    })
    .reduce((retus: any, currentarray: any) => {
      return currentarray;
    }, {});
  const MenuOfUserpermission = test_val[0];
  const heading = (
    <div>
      <ToggleButtonGroup
        sx={{ marginRight: "10px" }}
        color="primary"
        value={status}
        exclusive
        onChange={(_, newValue) => {
          if (newValue !== null) {
            handleStatusChange(newValue);
          }
        }}
        aria-label="Platform"
      >
        <ToggleButton
          value={0}
          title="Active Trade"
          onClick={() => handleStatusChange(0)}
          sx={{
            textTransform: "capitalize",
            height: "39px",
            marginTop: "3px",
            width: 150,
          }}
        >
          Active Trade
        </ToggleButton>
        <ToggleButton
          value={1}
          title="Inactive Trade"
          onClick={() => handleStatusChange(1)}
          sx={{
            textTransform: "capitalize",
            height: "39px",
            marginTop: "3px",
            width: 150,
          }}
        >
          Inactive Trade
        </ToggleButton>
      </ToggleButtonGroup>
    </div>
  );

  const column = [
    {
      sortable: true,
      headerName: "Algo Name",
      flex: 1,
      field: "algo",
      valueGetter: (params: any) => {
        return params.row.algo.name;
      },
      renderCell: (params: GridRenderCellParams<any>) => {
        const algoName = params.row.algo.name;

        return (
          <div
            title={algoName}
            onDoubleClick={() => handleDoubleClick(params)}
            style={{
              cursor: "pointer",
              height: "100%",
              width: "100%",
              textAlign: "left",
              marginTop: "20%",
            }}
          >
            {algoName}
          </div>
        );
      },
    },
    {
      sortable: true,
      headerName: "Strategy Name ",
      field: "strategy",
      valueGetter: (params: any) => {
        return params.row.strategy?.name;
      },
      renderCell: (params: GridRenderCellParams<any>) => {
        const strategyName = params.row.strategy?.name ?? "-";

        return (
          <div
            title={strategyName}
            onDoubleClick={() => handleDoubleClick(params)}
            style={{
              cursor: "pointer",
              height: "100%",
              width: "100%",
              textAlign: "left",
              marginTop: "20%",
            }}
          >
            {strategyName}
          </div>
        );
      },

      flex: 1,
    },
    {
      sortable: true,

      headerName: "Stock Name ",
      field: "stocknumber",
      flex: 1,
      valueGetter: (params: any) => {
        return params.row.stocks[0].name;
      },
      renderCell: (params: GridRenderCellParams<any>) => {
        const stocks = params.row.stocks; // Access the "stocks" array
        const algoName = stocks
          .map((stock: any) => `${stock.name} `)
          .join(", "); // Map and join the "name" property

        return (
          <div
            title={algoName}
            onDoubleClick={() => handleDoubleClick(params)}
            style={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              cursor: "pointer",
              height: "100%",
              width: "100%",
              textAlign: "left",
              marginTop: "20%",
            }}
          >
            {algoName}
          </div>
        );
      },
    },
    {
      sortable: true,

      headerName: "Accounts",
      field: "accounts",
      flex: 1,

      renderCell: (params: GridRenderCellParams<any>) => {
        // Access the "accounts" array
        const accounts = params.row.accounts;
        if (!accounts) {
          return (
            <div
              onDoubleClick={() => handleDoubleClick(params)}
              style={{
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                cursor: "pointer",
                height: "100%",
                width: "100%",
                textAlign: "left",
                marginTop: "20%",
              }}
              title="-"
            >
              -
            </div>
          );
        }

        // Use the map function to extract the "broker" and "client_id" properties
        const accountDetails = accounts
          .map((account: any) => `${account.broker} : ${account.client_id}  `)
          .join(","); // Map and join the "broker" and "client_id" properties

        return (
          <div
            onDoubleClick={() => handleDoubleClick(params)}
            style={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              cursor: "pointer",
              height: "100%",
              width: "100%",
              textAlign: "left",
              marginTop: "20%",
            }}
            title={accountDetails}
          >
            {accountDetails}
          </div>
        );
      },
    },
    {
      sortable: true,

      headerName: "Group",
      field: "groups",
      flex: 1,
      // valueGetter: (params: any) => {
      //   return params.row.groups[0].name;
      // },
      renderCell: (params: GridRenderCellParams<any>) => {
        // Access the "name" property from the "stocks" array

        const groups = params.row.groups; // Access the "stocks" array
        if (!groups) {
          return (
            <div
              onDoubleClick={() => handleDoubleClick(params)}
              style={{
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                cursor: "pointer",
                height: "100%",
                width: "100%",
                textAlign: "left",
                marginTop: "20%",
              }}
              title="-"
            >
              -
            </div>
          );
        }
        const algoName = groups
          .map((groups: any) => `${groups.name} `)
          .join(", "); // Map and join the "name" property

        return (
          <div
            title={algoName}
            onDoubleClick={() => handleDoubleClick(params)}
            style={{
              cursor: "pointer",
              height: "100%",
              width: "100%",
              textAlign: "left",
              marginTop: "20%",
            }}
          >
            {algoName}
          </div>
        );
      },
    },
    {
      headerName: "Expiry Date",
      flex: 1,
      field: "expiry_date",
      type: "dateTime",
      sortable: true,
      valueGetter: (params: any) => {
        return new Date(params.row.expiry_date);
      },
      renderCell: (params: GridRenderCellParams<any>) => {
        if (params.row.expiry_date === null) {
          return "-";
        }

        const date = new Date(params?.row?.expiry_date);

        // Format the date and time
        const formattedDateTime = `${date.toLocaleDateString()}`;

        return (
          <Tooltip title={formattedDateTime}>
            <div
              onDoubleClick={() => handleDoubleClick(params)}
              style={{
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                cursor: "pointer",
                height: "100%",
                width: "100%",
                textAlign: "left",
                marginTop: "20%",
              }}
            >
              {formattedDateTime}
            </div>
          </Tooltip>
        );
      },
    },
    {
      headerName: "Last Execution At",
      flex: 1,
      field: "last_order_time",
      valueGetter: (params: any) => {
        return new Date(params.row.expiry_date);
      },
      renderCell: (params: GridRenderCellParams<any>) => {
        if (params.row.last_order_time === null) {
          return "-";
        }
        // Assuming params?.row?.last_order_time is the epoch/unix timestamp
        const timestamp = params?.row?.last_order_time;
        const date = new Date(timestamp * 1000); // Convert seconds to milliseconds

        // Format the date and time
        const options: any = {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
          hour12: false, // Use 24-hour time format
          timeZone: "Asia/Kolkata", // Specify Indian Standard Time
        };

        const formattedDateTime = date.toLocaleString("en-IN", options);
        return (
          <Tooltip title={formattedDateTime}>
            <div
              onDoubleClick={() => handleDoubleClick(params)}
              style={{
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                cursor: "pointer",
                height: "100%",
                width: "100%",
                textAlign: "left",
                marginTop: "20%",
              }}
            >
              {formattedDateTime}
            </div>
          </Tooltip>
        );
      },
    },
    status === 0
      ? {
          headerName: "BUY/SELL",
          field: "Buy",
          flex: 1,
          type: "actions",
          renderCell: (params: any) => {
            const stocks = params.row.stocks;
            const account = params.row.accounts;
            const isDisabled = params.row.manualEntry === false;
            const tooltipMessage = isDisabled
              ? "Manual entry is currently disabled as there is an open trade in progress."
              : "";

            return (
              <div style={{ display: "grid", gap: "4px" }}>
                <Tooltip title={tooltipMessage} arrow>
                  <span>
                    {" "}
                    {/* Wrapper needed for disabled buttons to show tooltip */}
                    <Button
                      disabled={isDisabled}
                      style={{
                        color: "white",
                        cursor: isDisabled ? "not-allowed" : "pointer",
                        height: "20px",
                        fontFamily: "Roboto",
                        fontSize: "10px",
                        borderRadius: "5px",
                        backgroundColor: isDisabled ? "gray" : "green",
                      }}
                      onClick={() => {
                        if (!isDisabled) {
                          dispatcher(
                            add_AddTreadSetup_Manual({
                              stock_id: stocks[0]?.id,
                              segment: stocks[0]?.segment,
                              account_id: account[0]?.id,
                              order_type: "BUY",
                              quantity: params?.row?.quantity,
                              entry: 0,
                              target: params?.row?.target,
                              sl: params?.row?.stop_loss,
                              tsl: params?.row?.trailing_Stop_loss,
                              strategy_setting_id: params?.row?.strategy?.id,
                              strategy_name: params?.row?.strategy?.name,
                              algo_id: params?.row?.algo_id,
                              trade_setup_id: params?.row?.id,
                            })
                          );
                        }
                      }}
                    >
                      BUY
                    </Button>
                  </span>
                </Tooltip>

                <Tooltip title={tooltipMessage} arrow>
                  <span>
                    <Button
                      disabled={isDisabled}
                      style={{
                        color: "white",
                        height: "20px",
                        cursor: isDisabled ? "not-allowed" : "pointer",
                        fontFamily: "Roboto",
                        fontSize: "10px",
                        borderRadius: "5px",
                        backgroundColor: isDisabled ? "gray" : "#E10000",
                      }}
                      onClick={() => {
                        if (!isDisabled) {
                          dispatcher(
                            add_AddTreadSetup_Manual({
                              stock_id: stocks[0]?.id,
                              segment: stocks[0]?.segment,
                              account_id: account[0]?.id,
                              order_type: "SELL",
                              quantity: params?.row?.quantity,
                              entry: 0,
                              target: params?.row?.target,
                              sl: params?.row?.stop_loss,
                              tsl: params?.row?.trailing_Stop_loss,
                              strategy_setting_id: params?.row?.strategy?.id,
                              strategy_name: params?.row?.strategy?.name,
                              algo_id: params?.row?.algo_id,
                              trade_setup_id: params?.row?.id,
                            })
                          );
                        }
                      }}
                    >
                      SELL
                    </Button>
                  </span>
                </Tooltip>
              </div>
            );
          },
        }
      : null,

    true
      ? {
          headerName: "Action",
          field: "Action",
          flex: 1,
          type: "actions",
          renderCell: (params: any) => {
            return (
              <div
                onDoubleClick={() => handleDoubleClick(params)}
                style={{
                  cursor: "pointer",
                  height: "100%",
                  width: "100%",
                  textAlign: "left",
                  marginTop: "20%",
                }}
              >
                <TradesetupMessage
                  valuepopup={params.row.id}
                  editdata={params.row}
                ></TradesetupMessage>
              </div>
            );
          },
        }
      : {
          flex: 1,
          type: "actions",
          hide: true,
          display: false,
          renderCell: (params: any) => {
            return (
              <div
                onDoubleClick={() => handleDoubleClick(params)}
                style={{
                  cursor: "pointer",
                  height: "100%",
                  width: "100%",
                  textAlign: "left",
                  paddingTop: "20%",
                }}
              ></div>
            );
          },
        },
  ];

  const initialColumnVisibilityModel: any = {
    algo: true,
    strategy: true,
    stocknumber: true,
    accounts: true,
    groups: true,
    expiry_date: true,
    last_order_time: true,
    // BUY: true,
    Action: true,
  };

  const [columnVisibilityModel, setColumnVisibilityModel] = React.useState(
    initialColumnVisibilityModel
  );
  React.useEffect(() => {
    dispatcher(list_TreadSetupList({ status: 0 }));
    dispatcher(edit_filtermap({}));
    dispatcher(list_StrategiesList(""));
    dispatcher(list_AddorEditList(""));
    dispatcher(list_AccountList(""));
    dispatcher(list_ListGroupAccount(""));
    if (switchResponse) {
      dispatcher(false_switchTreadSetupvalues());
    }
    if (switchResponsealgodelete) {
      dispatcher(false_switchDeleteTreadSetupvalues());
    }
    return () => {
      dispatcher(clearData());
    };
  }, [
    addData,
    editData,
    deleteData,
    dispatcher,
    switchResponse,
    switchResponsealgodelete,
  ]);

  const action = (
    <button
      onClick={() => {
        dispatcher(false_switchTreadSetup_response()); // Close the Snackbar immediately
      }}
      style={{
        color: "white",
        backgroundColor: "transparent",
        border: "none",
        cursor: "pointer",
      }}
    >
      Close
    </button>
  );
  const actions = (
    <button
      onClick={() => {
        dispatcher(false_switchdeleteTreadSetup_response()); // Close the Snackbar immediately
      }}
      style={{
        color: "white",
        backgroundColor: "transparent",
        border: "none",
        cursor: "pointer",
      }}
    >
      Close
    </button>
  );
  const Addaction = (
    <button
      onClick={() => {
        dispatcher(false_switchTreadSetupadd_response()); // Close the Snackbar immediately
      }}
      style={{
        color: "white",
        backgroundColor: "transparent",
        border: "none",
        cursor: "pointer",
      }}
    >
      Close
    </button>
  );

  const Addactionbuyorsell = (
    <button
      onClick={() => {
        dispatcher(false_switchTreadSetupadd_response_Manual()); // Close the Snackbar immediately
      }}
      style={{
        color: "white",
        backgroundColor: "transparent",
        border: "none",
        cursor: "pointer",
      }}
    >
      Close
    </button>
  );

  return (
    <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={AddResponsebuyorsell} // Use alertOpen to control the Snackbar's visibility
        autoHideDuration={2000} // Set the desired auto-hide duration (in milliseconds)
        onClose={() => dispatcher(false_switchTreadSetupadd_response_Manual())}
        key={`top-right`}
        action={Addactionbuyorsell}
      >
        <Alert
          onClose={() =>
            dispatcher(false_switchTreadSetupadd_response_Manual())
          }
          severity="success"
        >
          {addDatabuyorsell.message}
        </Alert>
      </Snackbar>

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={AddResponse} // Use alertOpen to control the Snackbar's visibility
        autoHideDuration={2000} // Set the desired auto-hide duration (in milliseconds)
        onClose={() => dispatcher(false_switchTreadSetupadd_response())}
        key={`top-right`}
        action={Addaction}
      >
        <Alert
          onClose={() => dispatcher(false_switchTreadSetupadd_response())}
          severity="success"
        >
          {addData.message}
        </Alert>
      </Snackbar>

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={AddResponse} // Use alertOpen to control the Snackbar's visibility
        autoHideDuration={2000} // Set the desired auto-hide duration (in milliseconds)
        onClose={() => dispatcher(false_switchTreadSetupadd_response())}
        key={`top-right`}
        action={Addaction}
      >
        <Alert
          onClose={() => dispatcher(false_switchTreadSetupadd_response())}
          severity="success"
        >
          {addData.message}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={switchResponse} // Use alertOpen to control the Snackbar's visibility
        autoHideDuration={2000} // Set the desired auto-hide duration (in milliseconds)
        onClose={() => dispatcher(false_switchTreadSetup_response())}
        key={`top-right`}
        action={action}
      >
        <Alert
          onClose={() => dispatcher(false_switchTreadSetup_response())}
          severity="success"
        >
          {editData.message}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={switchResponsealgodelete} // Use alertOpen to control the Snackbar's visibility
        autoHideDuration={2000} // Set the desired auto-hide duration (in milliseconds)
        onClose={() => dispatcher(false_switchdeleteTreadSetup_response())}
        key={`top-right`}
        action={actions}
      >
        <Alert
          onClose={() => dispatcher(false_switchdeleteTreadSetup_response())}
          severity="success"
        >
          {deleteData.message}
        </Alert>
      </Snackbar>
      <div>
        <LinearProgress
          style={{
            color: "black",
            width: "100%",
            marginTop: "-15px",
            display:
              isLoadingdata || addDataIsLoading || editDataIsLoading
                ? "block"
                : "none",
          }}
        />
      </div>

      <Stack
        spacing={10}
        className="breadcrumbs"
        sx={{ margin: "0px 0px 1.5% 1%" }}
      >
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          {breadcrumbs}
        </Breadcrumbs>
      </Stack>
      {drawerOpen && selectedRowData && (
        <TradesetupView
          valuepopup={selectedRowData}
          closeDrawer={() => setDrawerOpen(false)}
        />
      )}

      <Grids
        row={data ?? []}
        column={column}
        Users={heading}
        open
        pagination
        // getRowHeight={() => "auto"}
        sideButton={<Tradesetupfilter></Tradesetupfilter>}
        refericon={
          <span
            title="Refresh"
            onClick={() => {
              dispatcher(list_TreadSetupList({ status }));
            }}
          >
            <RefreshIcon
              sx={{ fontSize: "24px", marginTop: "5px", cursor: "pointer" }}
            ></RefreshIcon>
          </span>
        }
        dialog={
          <DialogTradesetup
            successMessage={addData}
            isLoading={addDataIsLoading}
            disable={MenuOfUserpermission?.permission?.add === false}
            sx={{
              padding: "15px",
              background: "linear-gradient(to right, #FFFA02,#F2A618 )",
              color: "black",
              width: {
                xs: 60,
                sm: 100,
                md: 100,
                lg: 100,
                xl: 150,
              },
              height: "37px",
              borderRadius: "5px",
              fontFamily: "Roboto",
              textTransform: "capitalize",
              marginLeft: "7px",
              marginTop: "0px",
              marginRight: "-18px",
              "&:hover": {
                background:
                  "linear-gradient(to right, #FFFA02, #F2A618, #FFFA02  )",
              },
            }}
            placeholder="Role"
            getValues={(v: any) => {
              dispatcher(add_AddTreadSetup(v));
            }}
            Buttondialog={["Cancel"]}
            Dialogtitle={"Add Trade Setup"}
            Buttonname={"Add New"}
          />
        }
        Userpermissions={undefined}
        columnVisibilityModel={columnVisibilityModel}
        setColumnVisibilityModel={setColumnVisibilityModel}
      ></Grids>
      <Dialogs
        maxWidth={"xs"}
        open={switchOpen}
        subtitle={"Warning"}
        Dialog_Title={<img src={Warning} alt="Success..." height="100px" />}
        setOpen={() => dispatcher(false_switchTreadSetupvalues())}
        Dialog_Content={
          Uservalues.status === 0
            ? "Do you want to activate tradesetup ?"
            : "Do you want to de-activate tradesetup ?"
        }
        Dialog_Actions={
          <>
            <Button
              sx={{
                padding: "15px",
                color: "black",
                width: "100px",
                height: "37px",
                borderRadius: "5px",
                textTransform: "capitalize",
                border: "1px solid #F2A618",
                backgroundColor: "white",
                "&:hover": { backgroundColor: "white" },
              }}
              onClick={() => dispatcher(false_switchTreadSetupvalues())}
            >
              No
            </Button>
            <Button
              style={{
                color: "black",
                width: "100px",
                height: "37px",
                borderRadius: "5px",
                textTransform: "capitalize",
                marginLeft: "20px",
              }}
              autoFocus
              sx={{
                background: "linear-gradient(to right, #FFFA02,#F2A618 )",
                "&:hover": {
                  background: "linear-gradient(to right, #FFFA02,#F2A618 )",
                },
              }}
              onClick={() => dispatcher(edit_EditTreadSetup(Uservalues))}
            >
              yes
            </Button>
          </>
        }
      />
      <Dialogs
        maxWidth={"xs"}
        open={switchOpenalgodelete}
        subtitle={"Warning"}
        setOpen={() => dispatcher(false_switchDeleteTreadSetupvalues())}
        Dialog_Content={"Do you want to delete ?"}
        Dialog_Actions={
          <>
            <Button
              sx={{
                width: "18%",
                color: "black",
                borderRadius: "5px",
                textTransform: "capitalize",
                border: "1px solid #F2A618",
                padding: "5px 0",
                backgroundColor: "white",
                "&:hover": { backgroundColor: "white" },
              }}
              onClick={() => dispatcher(false_switchDeleteTreadSetupvalues())}
            >
              No
            </Button>
            <Button
              autoFocus
              sx={{
                background: "linear-gradient(to right, #FFFA02,#F2A618 )",
                "&:hover": {
                  background: "linear-gradient(to right, #FFFA02,#F2A618 )",
                },
                width: "18%",
                color: "black",
                borderRadius: "5px",
                textTransform: "capitalize",
                marginLeft: "5%",
              }}
              onClick={() =>
                dispatcher(delete_TreadSetup(Uservaluesalgodelete))
              }
            >
              yes
            </Button>
          </>
        }
      />
    </div>
  );
  // return (
  //   <div>
  //     <DataGrid rows={[]} columns={column} />
  //     {drawerOpen && <TradesetupView valuepopup={null} onClose={handleCloseDrawer} />}
  //   </div>
  // );
};

export default TreadSetup;
