import { call, put } from "redux-saga/effects";
import Axios, { AxiosResponse } from "axios";
import { expireSignature } from "../ExpireSignature/ExpireSignature_slicer";
import {
  failure_api_call_EditRedis,
  success_api_call_EditRedis,
} from "./edit_Redis_slicer";
import {
  failure_api_call_ListRedis,
  success_api_call_ListRedis,
} from "./list_Redis_slicer";

import {
  failure_api_call_ListRedisSub,
  success_api_call_ListRedisSub,
} from "./list_RedisSub_slicer";

//Edit redis
const EditRedisCall = async (a: any) => {
  const get_id = localStorage.getItem("Redisfilter");
  const get_token = localStorage.getItem("token");
  const edit = a.payload.parsedData;

  return Axios.put(
    `${process.env.REACT_APP_api_url}/v1/api/strategy/redis-key/update?key=${get_id}`,
    edit,
    {
      headers: {
        Authorization: "Bearer " + get_token,
      },
    }
  );
};
export function* EditRedisApi(e: any) {
  try {
    const response: AxiosResponse = yield call(EditRedisCall, e);
    yield put(success_api_call_EditRedis(response.data));
  } catch (e) {
    yield put(failure_api_call_EditRedis(e));
    yield put(expireSignature(e));
  }
}

//list redis
const ListRedisCall = async (a: any) => {
  const get_token = localStorage.getItem("token");
  return Axios.get(
    `${process.env.REACT_APP_api_url}/v1/api/strategy/redis-key`,
    {
      headers: {
        Authorization: "Bearer " + get_token,
      },
    }
  );
};
export function* ListRedisApi(e: any) {
  try {
    const response: AxiosResponse = yield call(ListRedisCall, e);
    let res = response?.data?.data?.map((x: any, i: any) => ({
      ...x,
      field_id: i + 1,
    }));

    yield put(success_api_call_ListRedis(res));
  } catch (e) {
    yield put(failure_api_call_ListRedis(e));
    yield put(expireSignature(e));
  }
}

//list redis SUB
const RedisSubListCall = async (a: any) => {
  const get_token = localStorage.getItem("token");

  return Axios.post(
    `${process.env.REACT_APP_api_url}/v1/api/strategy/redis-key/list?key=${a.payload}`,

    {
      headers: {
        Authorization: "Bearer " + get_token,
      },
    }
  );
};
export function* RedisSubListApi(e: any) {
  try {
    const response: AxiosResponse = yield call(RedisSubListCall, e);
    let res = response?.data?.data?.map((x: any, i: any) => ({
      ...x,
      field_id: i + 1,
    }));
    yield put(success_api_call_ListRedisSub(res));
  } catch (e) {
    yield put(failure_api_call_ListRedisSub(e));
    yield put(expireSignature(e));
  }
}
