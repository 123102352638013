import { FunctionComponent } from "react";
import { Route, Routes } from "react-router-dom";
import ErrorDialog from "../Component/ErrorDialog";
import ViewAlgo from "../pages/View Algo/ViewAlgo";
import Adduser from "../pages/Adduser/AddUser";
import Viewuser from "../pages/ViewUsers/ViewUser";
import Addstrategies from "../pages/AddStrategies/Addstrategies";
import MapStrategies from "../pages/MapStrategies/MapStrategies";
import ViewStrategies from "../pages/ViewStrategies/ViewStrategies";
import LiveTrade from "../pages/open_position/OpenPosition";
import TradeHistory from "../pages/tradehistory/TradeHistory";
import Profile from "../pages/profile/Profile";
import LabTabs from "../Component/TabforuserDetails";
import TabforAddalgo from "../Component/TabforAddalgo";
import TreadSetup from "../pages/Trade-setup/Trade-setup";
import MarketHoliday from "../pages/Marketholiday/Marketholiday";
import TabforGroupAccount from "../Component/TabforGroupofAccount";
import Dashboard from "../pages/Dashboard/dashboard";
import Stock from "../pages/Stock/Stock";
import UserPermission from "../pages/Roles_and_Permission/UserPermission";
import Role from "../pages/Roles_and_Permission/Roles";
import Menu from "../pages/Roles_and_Permission/Menu";
import OpenPositionmanual from "../pages/open_position_for_manual/OpenPosition_for_manual";
import TradeHistorymanual from "../pages/tradehistory_manual/TradeHistory_manual";
import UsageControl from "../pages/Usage_Control/Usage_Control";
import TabforHoldingandOrder from "../Component/TabforHoldingandOrder";
import BackTest from "../pages/backtest/backtest";
import Backresult from "../pages/Backtest_result/Backtest_result";
import Trade_History from "../pages/setup_hisory/setup_history";
import Redis from "../pages/Redis/Redis";

// this is interface
interface RoutingPagesProps {}
// this is overall function
const RoutingPages: FunctionComponent<RoutingPagesProps> = () => {
  return (
    <div style={{ height: "95%" }}>
      {/* Routing for all pages */}
      <Routes>
        <Route path="/usagecontrol" element={<UsageControl></UsageControl>} />
        <Route path="/historicaldata" element={<Redis />} />

        <Route
          path="/marketholiday"
          element={<MarketHoliday></MarketHoliday>}
        />
        <Route path="/viewalgo" element={<ViewAlgo></ViewAlgo>} />
        <Route path="/addalgo" element={<TabforAddalgo></TabforAddalgo>} />
        <Route path="/adduser" element={<Adduser></Adduser>} />
        <Route path="/viewuser" element={<Viewuser></Viewuser>} />
        <Route
          path="/orderandholding"
          element={<TabforHoldingandOrder></TabforHoldingandOrder>}
        />
        <Route
          path="/groupaccount"
          element={<TabforGroupAccount></TabforGroupAccount>}
        />
        <Route path="/dashboard" element={<Dashboard></Dashboard>} />
        <Route path="/Stock" element={<Stock></Stock>} />
        <Route path="/Role" element={<Role></Role>} />
        <Route path="/Menu" element={<Menu></Menu>} />
        <Route
          path="/manualopenposition"
          element={<OpenPositionmanual></OpenPositionmanual>}
        />
        <Route
          path="/manualtradehistory"
          element={<TradeHistorymanual></TradeHistorymanual>}
        />
        <Route
          path="/addstrategies"
          element={<Addstrategies></Addstrategies>}
        />
        <Route
          path="/UserPermission"
          element={<UserPermission></UserPermission>}
        />
        <Route
          path="/map-strategies"
          element={<MapStrategies></MapStrategies>}
        />
        <Route path="/trade-setup" element={<TreadSetup></TreadSetup>} />
        <Route
          path="/viewstrategies"
          element={<ViewStrategies></ViewStrategies>}
        />
        <Route path="/Userdetails" element={<LabTabs />}></Route>
        <Route path="/tradingaccount" element={<LabTabs />} />
        <Route
          path="/ErrorDialog"
          element={<ErrorDialog></ErrorDialog>}
        ></Route>
        <Route path="/openposition" element={<LiveTrade />} />
        <Route path="/tradehistory" element={<TradeHistory />} />
        <Route path="/backtest" element={<BackTest />} />
        <Route path="/backreport" element={<Backresult />} />
        <Route path="/tradesetup-history" element={<Trade_History />} />
        <Route path="/profile" element={<Profile />} />
      </Routes>
    </div>
  );
};

export default RoutingPages;
