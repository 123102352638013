import { createSlice } from "@reduxjs/toolkit";
// this code for slice value for list usercontrol
export const ListRedis = createSlice({
  name: "ListRedis",
  initialState: {
    isLoading: false,
    isSuccess: false,
    isFirst: true,
    data: [],
  },
  reducers: {
    list_ListRedis: (state, action?) => {
      state.isLoading = true;
    },
    success_api_call_ListRedis: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
      state.isSuccess = true;
      state.isFirst = true;
    },
    failure_api_call_ListRedis: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
    ListRedis_is_first: (state, action) => {
      state.isFirst = false;
    },
    clearDataListRedis: (state) => {
      state.data = [];
    },
  },
});
export default ListRedis.reducer;
export const {
  list_ListRedis,
  success_api_call_ListRedis,
  failure_api_call_ListRedis,
  ListRedis_is_first,
  clearDataListRedis,
} = ListRedis.actions;
