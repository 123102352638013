import { createSlice } from "@reduxjs/toolkit";
// this code for slice value for edit usercontrol
export const EditRedis = createSlice({
  name: "EditRedis",
  initialState: {
    isLoading: false,
    isSuccess: false,
    api_completed: false,
    isFirst: true,
    switchResponse: false,

    data: [],
  },
  reducers: {
    edit_EditRedis: (state, _) => {
      state.isLoading = true;
    },
    success_api_call_EditRedis: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
      state.isSuccess = true;
      state.api_completed = true;
      state.isFirst = true;
      state.switchResponse = true;
    },
    failure_api_call_EditRedis: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      state.api_completed = true;
      state.isSuccess = false;
      state.switchResponse = true;
    },
    EditRedis_is_success: (state) => {
      state.isSuccess = false;
    },
    EditRedis_complete_api: (state) => {
      state.api_completed = false;
    },
    clear_EditRedis_Data: (state) => {
      state.data = [];
    },

    false_switchEditRedis_response: (state) => {
      state.switchResponse = false;
    },
  },
});
export default EditRedis.reducer;
export const {
  edit_EditRedis,
  success_api_call_EditRedis,
  EditRedis_complete_api,
  failure_api_call_EditRedis,
  EditRedis_is_success,
  false_switchEditRedis_response,
  clear_EditRedis_Data,
} = EditRedis.actions;
